export const getData = () => {
    return new Promise((resolve) => {
        $.ajax({
            url: "/Admin/VideoChannel/channels",
            ContentType: 'application/json',
            type: 'get',
            cache: false,
        })
            .done(function (h) {
                resolve(h);
            })
    });
};